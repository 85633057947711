import './App.css';
import { Outlet } from 'react-router-dom';
import Topo from './Components/Layout/Topo';
import SessaoAuth from './Hooks/SessaoAuth';
import LateralEsq from './Components/Layout/LateralEsq';
import { Box, createTheme, ThemeProvider, CssBaseline } from '@mui/material';

function App() {

  const { sessao } = SessaoAuth();
  const defaultTheme = createTheme();

  return (
    <div className="App" style={{height: '100%'}} >
      <ThemeProvider theme={defaultTheme}>
        {sessao?.accessToken ? <Topo /> : null}
        <Box sx={{ display: 'flex' }} boxShadow={1}>
          <CssBaseline />
          {sessao?.accessToken ? <LateralEsq /> : null}
          <Box
            component="main"
            height={'auto'}
            sx={{
              flexGrow: 1,
              width: '75%'
            }}
          >
          <Outlet />
          </Box>
        </Box>
      </ThemeProvider>
    </div>
  )
}

export default App;
