import React from 'react';
import { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import Sidebar from './Sidebar';
import ChatWindow from './ChatWindow';

const Mensagens = () => {

  const [id, setId] = useState('0');
  const [nomeCliente, setNomeCliente] = useState('');
  const [numero, setNumero] = useState('0');
  const [situacao, setSituacao] = useState('F');

  const atualizarID = (novoRegistro) => {
    if (novoRegistro === 0) {
      setId('0');
      setNumero('0');
      setNomeCliente('');
      setSituacao('F');
    } else {
      setId(novoRegistro.id);
      setNumero(novoRegistro.numero);
      setNomeCliente(novoRegistro.nome);
      setSituacao(novoRegistro.situacao);
    }
  }

  useEffect(() => {

  }, []);

  return (
    <Box display={'flex'} height={'100%'}>
      <Sidebar atualizarID={atualizarID} />
      <ChatWindow id={id} numero={numero} nomeCliente={nomeCliente} situacao={situacao} />
    </Box>
  );
};

export default Mensagens;

