import * as React from 'react';
import { useRef, useState, useEffect } from 'react';
import SessaoAuth from '../../Hooks/SessaoAuth';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from '../../Api/axios';
import { useNavigate } from 'react-router-dom';
import './Login.css';
import { Alert, CircularProgress, Stack } from '@mui/material';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://www.faroit.com.br/">
                Faro Soluções Empresariais Ltda
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();
const LOGIN_URL = '/login';

export default function Login() {

    const { setSessao } = SessaoAuth();

    const emailRef = useRef();
    const senhaRef = useRef();

    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');

    const navigate = useNavigate();

    const [showErro, setShowErro] = useState(false);
    const [mensErro, setMensErro] = useState('');

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        emailRef.current.focus();
    }, []);

    const atualEmail = (e) => {
        setEmail(e.target.value);
        setShowErro(false);
    }

    const atualSenha = (e) => {
        setSenha(e.target.value);
        setShowErro(false);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            const response = await axios.post(LOGIN_URL,
                JSON.stringify({ email, senha }),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': '*/*'
                    },
                    withCredentials: false
                }
            );

            const accessToken = response?.data?.accessToken;
            const roles = response?.data?.roles;
            const usuario_nome = response?.data?.usuario_nome;
            const usuario_id = response?.data?.usuario_id;

            setSessao({ email, senha, roles, accessToken, usuario_nome, usuario_id });

            setEmail('');
            setSenha('');

            navigate("/");

        } catch (error) {
            setSenha('');
            if (!error?.response) {
                setMensErro("Servidor não responde");
            } else if (error.response?.status === 400) {
                setMensErro(error.response?.data?.erro);
            } else if (error.response?.status === 401) {
                setMensErro(error.response?.data?.erro);
            } else {
                setMensErro("Erro desconhecido.");
            }
            setShowErro(true);
        }

        setLoading(false);
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Grid container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{
                        backgroundImage:
                            'url("/images/Logosys.png")',
                        backgroundColor: (t) =>
                            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'left',
                    }}
                />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Identifique-se
                        </Typography>
                        {
                            showErro && (
                                <Stack sx={{ width: '100%' }} spacing={1}>
                                    <Alert severity="error">{mensErro}</Alert>
                                </Stack>
                            )}
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                                onChange={(e) => atualEmail(e)}
                                value={email}
                                ref={emailRef}
                                autoComplete="email"
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                ref={senhaRef}
                                name="senha"
                                label="Senha"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                onChange={(e) => atualSenha(e)}
                                value={senha}
                            />
                            <FormControlLabel
                                control={<Checkbox value="remember" color="primary" />}
                                label="Lembre-se de mim."
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Entrar
                                {loading && (
                                    <CircularProgress
                                        size={35}
                                        sx={{
                                            color: '#dcdcdc',
                                            position: 'absolute',
                                            top: 1,
                                            left: '50% + 20px',
                                            zIndex: 1,
                                        }}
                                    />
                                )}
                            </Button>
                            <Grid container>
                                <Grid item xs>
                                    <Link href="#" variant="body2">
                                        Esqueceu sua senha?
                                    </Link>
                                </Grid>
                            </Grid>
                            <Copyright sx={{ mt: 5 }} />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}
