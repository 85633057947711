import React from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { mainListItems, secondaryListItems } from './Menu';
import { Box, Divider, IconButton, List, styled, Toolbar } from '@mui/material';
import { useState } from 'react';
import MuiDrawer from '@mui/material/Drawer';

const drawerWidth = 240;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      '& .MuiDrawer-paper': {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: 'border-box',
        ...(!open && {
          overflowX: 'hidden',
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          width: theme.spacing(7),
          [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
          },
        }),
      },
    }),
  );

const LateralEsq = () => {

    const [openMenu, setOpenMenu] = useState(true);
    const toggleDrawerMenu = () => {
        setOpenMenu(!openMenu);
    };

    return (
        <Box
        overflow={'auto'}
        >
            <Drawer variant="permanent" open={openMenu}>
                <Toolbar
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        background: '#dcdcdc',
                        justifyContent: 'flex-end',
                        px: [1],
                    }}
                >
                    <IconButton onClick={toggleDrawerMenu}>
                        { openMenu && <ChevronLeftIcon /> }
                        { !openMenu && <ChevronRightIcon /> }
                    </IconButton>
                </Toolbar>
                <Divider />
                <List component="nav">
                    {mainListItems}
                    <Divider sx={{ my: 1 }} />
                    {secondaryListItems}
                </List>
            </Drawer>
        </Box>
    )
}

export default LateralEsq
