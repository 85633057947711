
import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import { DisplaySettings, Factory, Storefront, WhatsApp } from '@mui/icons-material';
import People from '@mui/icons-material/People';

export const mainListItems = (
  <React.Fragment>
    <ListItemButton>
      <ListItemIcon>
        <WhatsApp />
      </ListItemIcon>
      <ListItemText primary="Whatsapp" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Contatos" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <Factory />
      </ListItemIcon>
      <ListItemText primary="Empresas" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <DisplaySettings />
      </ListItemIcon>
      <ListItemText primary="Configurações" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <LayersIcon />
      </ListItemIcon>
      <ListItemText primary="Integrations" />
    </ListItemButton>
  </React.Fragment>
);

export const secondaryListItems = (
  <React.Fragment>
    <ListSubheader component="div" inset>
      Relatórios
    </ListSubheader>
    <ListItemButton>
      <ListItemIcon>
        <People />
      </ListItemIcon>
      <ListItemText primary="Contatos" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <Storefront />
      </ListItemIcon>
      <ListItemText primary="Empresas" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <BarChartIcon />
      </ListItemIcon>
      <ListItemText primary="Histórico" />
    </ListItemButton>
  </React.Fragment>
);
