import * as React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import axios from '../../Api/axios';
import { Box, List, ListItemAvatar, Avatar, ListItemText, Typography, Divider, Stack, Alert, ListItemButton } from '@mui/material';
import { Mail, MailOutline } from '@mui/icons-material';
import { format } from 'date-fns';

const URL_CONTATOS = '/mensagens/fila';

const Sidebar = ({ atualizarID }) => {

  const [contatos, setContatos] = useState([]);
  const [showErro, setShowErro] = useState(false);
  const [mensErro, setMensErro] = useState('Erro');

  const fetchContatos = async (event) => {

    try {

      const response = await axios.get(URL_CONTATOS,
        {
          // query URL without using browser cache
          headers: {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0',
          },
        });

      setContatos(response.data);

      if (response.data.length === 0) {
        atualizarID(0);
      }

      setShowErro(false);

    } catch (error) {
      if (!error?.response) {
        setMensErro("Servidor não responde");
      } else if (error.response?.status === 400) {
        setMensErro(error.response?.data?.erro);
      } else if (error.response?.status === 401) {
        setMensErro(error.response?.data?.erro);
      } else {
        setMensErro("Erro desconhecido.");
      }
      setShowErro(true);
    }
  };

  useEffect(() => {

    fetchContatos();

    // Atualizar dados a cada 20 segundos
    const intervalo = setInterval(fetchContatos, 3000);

    // Limpar intervalo ao desmontar o componente
    return () => clearInterval(intervalo);

  });

  function tratarTexto(texto) {

    const textoEmojis = replaceUnicodeWithEmojis(texto);

    //Tratar quebras de Linhas
    var quebra = textoEmojis;
    var quebraLinha = quebra.replace('\\n', '\n');
    while (quebra !== quebraLinha) {
      quebra = quebraLinha;
      quebraLinha = quebra.replace('\\n', '\n');
    }

    return quebra;
  }

  function unicodeToEmoji(unicode) {
    //console.log("UNICODE: " , unicode);
    const codePoint1 = unicode.replace('\\u', '0x');

    const retorno = String.fromCodePoint(parseInt(codePoint1, 16));
    return retorno;
  }

  function replaceUnicodeWithEmojis(text) {
    const emojiRegex = /\\u[0-9a-f]{4,5}/g;
    const retorno = text.replace(emojiRegex, (match) => unicodeToEmoji(match));

    return retorno;
  }


  return (
    <Box
      overflow={'auto'}
      width="300px"
      bgcolor="background.paper"
      display="flex"
      flexDirection="column"
    >
      <Box p={2} sx={{ bgcolor: '#dcdcdc' }}>
        <Typography variant="h6">Contatos</Typography>
      </Box>
      <Divider />
      {
        showErro && (
          <Stack sx={{ width: '100%' }} spacing={1}>
            <Alert severity="error">{mensErro}</Alert>
          </Stack>
        )}
      <List>
        {contatos.map((value) => (
          <ListItemButton onClick={() => atualizarID(value)} >
            <ListItemAvatar>
              <Avatar sx={{ background: "#1976d2" }} >
                {value.situacao === "A" && (<Mail />)}
                {value.situacao === "F" && (<MailOutline />)}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <React.Fragment>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {tratarTexto(value.nome)}
                  </Typography>
                </React.Fragment>}
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: 'block' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    Número: {value.numero}
                  </Typography>
                  <Typography
                    sx={{ display: 'block' }}
                    component="span"
                    color="text.primary"
                    variant="caption"
                  >
                    {format(new Date(value.data_hora), 'dd/MM/yyyy HH:mm')}
                  </Typography>
                  {value.situacao === "F" && (
                    <Typography
                      sx={{ display: 'block' }}
                      component="span"
                      color="text.primary"
                      variant="caption"
                    >
                      Encerrado
                    </Typography>
                  )}
                  {value.situacao === "A" && (
                    <Typography
                      sx={{ display: 'block', fontWeight: 'bold' }}
                      component="span"
                      color="text.primary"
                      variant="caption"
                    >
                      {tratarTexto(value.tecnico)}
                    </Typography>
                  )}
                </React.Fragment>}
            />
          </ListItemButton>
        ))}
      </List>
    </Box>
  );
};

export default Sidebar;
