import { useLocation, Outlet, Navigate } from "react-router-dom";
import SessaoAuth from "../../Hooks/SessaoAuth";

const RequireAuth = () => {

    const { sessao } = SessaoAuth();
    const location = useLocation();

    return (
        sessao?.accessToken
            ? <Outlet />
            : <Navigate to="/login" state={{ from: location }} replace />
    );
}

export default RequireAuth
