import React from 'react';
import { useEffect } from 'react';
import { useState, useRef } from 'react';
import axios from '../../Api/axios';
import { Box, Typography, Divider, TextField, IconButton, Stack, Alert, Button, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import SessaoAuth from '../../Hooks/SessaoAuth';
import MessageBox from './MessageBox';
import ImageBox from './ImageBox';

var URL_MENS = '/mensagens/mensagens/0';
var URL_ENVIAR = '/mensagens/enviar';
var URL_FECHAR = '/mensagens/fechar';

const ChatWindow = ({ id, numero, nomeCliente, situacao }) => {

  const [mensagens, setMensagens] = useState([]);
  const [showErro, setShowErro] = useState(false);
  const [mensErro, setMensErro] = useState('Erro');
  const [mensagem, setMensagem] = useState('');
  const [processando, setProcessando] = useState(false);

  const { sessao } = SessaoAuth();

  const usuario_nome = sessao["usuario_nome"];
  const usuario_id = sessao["usuario_id"];

  const mensagemRef = useRef();

  // Envio de mensagem  
  const atualMensagem = (e) => {
    setMensagem(e.target.value);
  }

  const handleEnviar = async (event) => {

    setProcessando(true);

    try {
      await axios.post(URL_ENVIAR,
        JSON.stringify({ mensagem, usuario_nome, usuario_id, numero }),
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': '*/*'
          },
          withCredentials: false
        });

      setMensagem('');
      setShowErro(false);
      setProcessando(false);

    } catch (error) {
      if (!error?.response) {
        setMensErro("Servidor não responde");
      } else if (error.response?.status === 400) {
        setMensErro(error.response?.data?.erro);
      } else if (error.response?.status === 401) {
        setMensErro(error.response?.data?.erro);
      } else {
        setMensErro("Erro desconhecido.");
      }
      setShowErro(true);
    }
  };

  const fecharAtendimento = async (event) => {

    try {

      console.log(id + " - " + usuario_nome + " - " + usuario_id + " - " + numero);

      const response = await axios.post(URL_FECHAR,
        JSON.stringify({ id, usuario_nome, usuario_id, numero }),
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': '*/*'
          },
          withCredentials: false
        });

      console.log("Resposta: " + response.data);

      setMensagem('');
      setShowErro(false);

    } catch (error) {
      if (!error?.response) {
        setMensErro("Servidor não responde");
      } else if (error.response?.status === 400) {
        setMensErro(error.response?.data?.erro);
      } else if (error.response?.status === 401) {
        setMensErro(error.response?.data?.erro);
      } else {
        setMensErro("Erro desconhecido.");
      }
      setShowErro(true);
    }
  };


  // Leitura de Mensagens  
  URL_MENS = `/mensagens/mensagens/${id}`;

  const fetchMensagens = async (event) => {

    try {

      const response = await axios.get(URL_MENS,
        {
          headers: {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0',
          },
        });

      setMensagens(response.data);

      setShowErro(false);

    } catch (error) {
      if (!error?.response) {
        setMensErro("Servidor não responde");
      } else if (error.response?.status === 400) {
        setMensErro(error.response?.data?.erro);
      } else if (error.response?.status === 401) {
        setMensErro(error.response?.data?.erro);
      } else {
        setMensErro("Erro desconhecido.");
      }
      setShowErro(true);
    }
  };

  useEffect(() => {

    fetchMensagens();

    // Atualizar dados a cada 10 segundos
    const intervalo = setInterval(fetchMensagens, 3000);

    // Limpar intervalo ao desmontar o componente
    return () => clearInterval(intervalo);
  }, []);

  useEffect(() => {

    setMensagens([]);
    fetchMensagens();

  }, [id]);

  const [confirmarFechar, setConfirmarFechar] = useState(false);

  const handleFecharConf = (e) => {
    setConfirmarFechar(false);
    fecharAtendimento(e);
  };

  const handleFecharCanc = (e) => {
    setConfirmarFechar(false);
  };

  const handleFecharAtend = (e) => {
    setConfirmarFechar(true);
  };

  return (
    <Box
      flex={1}
      display="flex"
      flexDirection="column"
      height={'100%'}
      overflow={'auto'}
      width={'100%'}
    >
      <Dialog
        open={confirmarFechar}
        onClose={handleFecharAtend}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >

        <DialogTitle id="alert-dialog-title">
          {"Deseja finalizar o atendimento?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Caso você confirme, não conseguirá mais responder ao usuário.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFecharCanc}>Cancelar</Button>
          <Button onClick={handleFecharConf} autoFocus>Confirmar</Button>
        </DialogActions>
      </Dialog>


      <Box
        p={2} sx={{ bgcolor: '#dcdcdc' }}
        display={'flex'}
        flexDirection={'rows'}
      >
        <Box width={'50%'} >
          <Typography variant="h6">Cliente: {nomeCliente} </Typography>
        </Box>
        <Box sx={{ textAlign: 'right' }} width={'50%'} >
          <Typography variant="h6">Atendente: {usuario_nome} </Typography>
        </Box>
      </Box>
      <Divider />
      <Box
        display={'flex'}
        flexDirection={'column'}
        height={'100%'}
        overflow={'auto'}
      >
        {
          showErro && (
            <Stack sx={{ width: '100%' }} spacing={1}>
              <Alert severity="error">{mensErro}</Alert>
            </Stack>
          )}
        <Box
          p={2}
          width={'100%'}
          overflow={'auto'}
        >
          {mensagens.map((mens) => (
            (mens.tipo === "text" && (<MessageBox mens={mens} numero={numero} />)) ||
            (mens.tipo === "image" && (<ImageBox mens={mens} numero={numero} />))
          ))}
        </Box>
        <Divider />
        {situacao !== "F" && (
          <Box>
            <Stack
              direction={'row'}
              spacing={2}
              sx={{
                marginTop: 2,
                marginBottom: 2
              }}
            >
              <Button variant="outlined" color="error" onClick={handleFecharAtend}>Finalizar Atendimento</Button>
              <Button variant="outlined">Enviar Imagem</Button>
            </Stack>
            <Box
              display="flex"
              alignItems="center"
            >
              <TextField
                fullWidth
                multiline
                rows={3}
                placeholder="Mensagem"
                variant="outlined"
                required
                ref={mensagemRef}
                name="mensagem"
                id="mensagem"
                onChange={(e) => atualMensagem(e)}
                value={mensagem}
                InputProps={{
                  readOnly: processando,
                }}
              />
              <IconButton
                color="primary"
                onClick={handleEnviar}
                InputProps={{
                  readOnly: processando,
                }}
              >
                <SendIcon />
              </IconButton>
            </Box>
          </Box>
        )}
      </Box >
    </Box>
  );
};

export default ChatWindow;
