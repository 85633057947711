import React from 'react';
import { Box, Typography, Paper } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { format } from 'date-fns';

const MessageBox = ({ mens, numero }) => {

    var isSender = true;
    const message = mens.mensagem;
    const sender = mens.remetente;
    const timestamp = format(new Date(mens.data_hora), 'dd/MM/yyyy HH:mm');
    const acao = mens.acao;
    const nome = mens.nome;
    const status = mens.status;


    if (mens.remetente === numero) {
        isSender = false;
    }

    function tratarTexto(texto) {

        const textoEmojis = replaceUnicodeWithEmojis(texto);

        //Tratar quebras de Linhas
        var quebra = textoEmojis;
        var quebraLinha = quebra.replace('\\n', '\n');
        while (quebra !== quebraLinha) {
            quebra = quebraLinha;
            quebraLinha = quebra.replace('\\n', '\n');
        }

        return quebra;
    }

    function unicodeToEmoji(unicode) {
        //console.log("UNICODE: " , unicode);
        const codePoint1 = unicode.replace('\\u', '0x');

        const retorno = String.fromCodePoint(parseInt(codePoint1, 16));
        return retorno;
    }

    function replaceUnicodeWithEmojis(text) {
        const emojiRegex = /\\u[0-9a-f]{4,5}/g;
        const retorno = text.replace(emojiRegex, (match) => unicodeToEmoji(match));

        return retorno;
    }

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: isSender ? 'flex-end' : 'flex-start',
                mb: 2,
                maxWidth: '100%'
            }}
        >
            <Paper
                elevation={3}
                sx={{
                    maxWidth: '48%',
                    padding: '10px',
                    backgroundColor: isSender ? '#DCF8C6' : '#FFFFFF',
                    borderRadius: '10px',
                    borderTopRightRadius: isSender ? '0' : '10px',
                    borderTopLeftRadius: isSender ? '10px' : '0',
                    position: 'relative',
                }}
            >
                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                    {isSender ? sender : nome}
                </Typography>
                {!isSender &&
                    (<Typography variant="caption" sx={{ display: 'block', textAlign: 'left' }}>
                        {sender}
                    </Typography>)}
                <Typography variant="body1" sx={{ mb: 1 }}>
                    {tratarTexto(message).split('\n').map((line, index) => (
                        <React.Fragment key={index}>
                            {tratarTexto(line)}
                            <br />
                        </React.Fragment>
                    ))}
                </Typography>
                <Typography variant="caption" sx={{ display: 'block', textAlign: 'right' }}>
                    {timestamp}
                    {
                        (status === 'sent' && (<CheckIcon style={{ color: 'gray', fontSize: 12 }} />)) ||
                        (status === 'delivered' && (<DoneAllIcon style={{ color: 'gray', fontSize: 12 }} />)) ||
                        (status === 'read' && (<DoneAllIcon style={{ color: 'blue', fontSize: 12 }} />))
                    }
                </Typography>
                {acao !== " " && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '89%',
                            bottom: '5px',
                            right: '5px',
                            fontSize: '18px',
                        }}
                    >
                        {tratarTexto(acao)}
                    </Box>
                )}
            </Paper>
        </Box>
    );
};

export default MessageBox;
