import React from 'react';
import { useState } from 'react';
import { Box, Typography, Paper, Modal } from '@mui/material';
import { format } from 'date-fns';

var URL_IMAGE = "https://webservice.faroit.com.br/APIRest/whatsapp/v1/imagens/imagem";

const ImageBox = ({ mens, numero }) => {

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    var isSender = true;
    const message = mens.mensagem;
    const sender = mens.remetente;
    const timestamp = format(new Date(mens.data_hora), 'dd/MM/yyyy HH:mm');
    const acao = mens.acao;
    const image_id = mens.image_id;
    const nome = mens.nome;

    if (mens.remetente === numero) {
        isSender = false;
    }

    function tratarTexto(texto) {

        const textoEmojis = replaceUnicodeWithEmojis(texto);

        //Tratar quebras de Linhas
        var quebra = textoEmojis;
        var quebraLinha = quebra.replace('\\n', '\n');
        while (quebra !== quebraLinha) {
            quebra = quebraLinha;
            quebraLinha = quebra.replace('\\n', '\n');
        }

        return quebra;
    }

    function unicodeToEmoji(unicode) {
        //console.log("UNICODE: " , unicode);
        const codePoint1 = unicode.replace('\\u', '0x');

        const retorno = String.fromCodePoint(parseInt(codePoint1, 16));
        return retorno;
    }

    function replaceUnicodeWithEmojis(text) {
        const emojiRegex = /\\u[0-9a-f]{4,5}/g;
        const retorno = text.replace(emojiRegex, (match) => unicodeToEmoji(match));

        return retorno;
    }

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: isSender ? 'flex-end' : 'flex-start',
                mb: 2,
                maxWidth: '100%'
            }}
        >
            <Paper
                elevation={3}
                sx={{
                    maxWidth: '48%',
                    padding: '10px',
                    backgroundColor: isSender ? '#DCF8C6' : '#FFFFFF',
                    borderRadius: '10px',
                    borderTopRightRadius: isSender ? '0' : '10px',
                    borderTopLeftRadius: isSender ? '10px' : '0',
                    position: 'relative',
                }}
            >
                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                    { isSender ? sender : nome} 
                </Typography>
                { !isSender && 
                (<Typography variant="caption" sx={{ display: 'block', textAlign: 'left' }}>
                    {sender}
                </Typography>)}
                <Box
                    component="img"
                    src={URL_IMAGE + "/" + image_id}
                    alt={message}
                    sx={{
                        maxWidth: '48%',
                        maxHeight: '150px',
                        display: 'flex',
                        justifyContent: isSender ? 'flex-end' : 'flex-start',
                        //mb: 2
                    }}
                    onClick={handleOpen}
                />
                <Typography variant="body1" sx={{ mb: 1 }}>
                    {tratarTexto(message)}
                </Typography>
                <Typography variant="caption" sx={{ display: 'block', textAlign: 'right' }}>
                    {timestamp}
                </Typography>
                {acao !== " " && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '89%',
                            bottom: '5px',
                            right: '5px',
                            fontSize: '18px',
                        }}
                    >
                        {tratarTexto(acao)}
                    </Box>
                )}
            </Paper>
            <Modal open={open} onClose={handleClose}>
                <Box
                    component="img"
                    src={URL_IMAGE + "/" + image_id}
                    alt={message}
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        maxWidth: '90%',
                        maxHeight: '90%',
                        borderRadius: 1,
                        boxShadow: 24,
                    }}
                />
            </Modal>
        </Box>
    );
};

export default ImageBox;
