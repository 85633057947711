import { createContext, useState } from 'react';

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {

    const [sessao, setSessao] = useState({});

    return (
        <AuthContext.Provider value={ { sessao, setSessao } }>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;
